import { Form, json } from "@remix-run/react";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
} from "@remix-run/cloudflare";
import { route } from "app/utils/routes";
import { getUser, handleNewSession, login } from "app/utils/auth.server";
import { getDb } from "app/utils/db.server";

export async function loader({ request, context }: LoaderFunctionArgs) {
  const user = await getUser(request, context);

  if (user) {
    return redirect(user.admin ? route("/sudo/businesses") : route("/admin"));
  }

  return null;
}

export async function action({ request, context }: ActionFunctionArgs) {
  const formData = await request.formData();
  const email = formData.get("email") as string | null;
  const password = formData.get("password") as string | null;

  if (!password || !email) {
    return json({
      error: "Missing email or password",
    });
  }

  const session = await login({
    email: email,
    password,
    context: context,
  });

  if (!session) {
    return json({
      error: "Failed to login",
    });
  }

  const db = getDb(context);

  const businessUser = await db.businessUser.findFirst({
    where: {
      id: session.userId,
    },
    select: {
      admin: true,
      id: true,
      businesses: {
        select: {
          id: true,
        },
      },
    },
  });

  const redirectTo = new URL(request.url).searchParams.get("redirectTo");

  return handleNewSession({
    request,
    session,
    remember: true,
    redirectTo: redirectTo
      ? (redirectTo as string)
      : businessUser?.admin
      ? route("/sudo/businesses")
      : route("/admin"),
  });
}

export default function Auth() {
  return (
    <div className="flex flex-row">
      <div className="hidden relative lg:block w-1/3 bg-purple-950">
        <img
          src="/logo_frame.png"
          alt="logo"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 top-0 h-full bg-linear-to-b from-black/70 to-black/5" />
        <div className="absolute top-5 left-5">
          <img
            src="/logo_with_icon.png"
            alt="logo"
            className="w-60 object-contain"
          />
        </div>
      </div>
      <div className="flex flex-col grow min-h-screen justify-center items-center">
        <div>
          <img
            src="/logo_with_icon.png"
            alt="logo"
            className="w-72 lg:hidden object-contain"
          />
        </div>

        <h1 className="text-center text-4xl font-bold mt-10">Log In</h1>

        <p className="text-center text-lg text-muted-foreground mt-2">
          View your PunchPocket details and manage your cards.
        </p>

        <Form
          method="post"
          className="flex flex-col w-full mt-10 px-10 max-w-lg"
        >
          <div>
            <Label htmlFor="email">Email</Label>
            <Input className="mt-1" id="email" type="email" name="email" />
          </div>

          <div className="mt-4">
            <Label htmlFor="password">Password</Label>
            <Input
              className="mt-1"
              id="password"
              type="password"
              name="password"
            />
          </div>

          <Button type="submit" className="mt-8">
            Log In
          </Button>
        </Form>
      </div>
    </div>
  );
}
